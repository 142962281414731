import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToasterService } from "../../services/toaster.service";

@Component({
	selector: "app-add-faq",
	templateUrl: "./add-faq.component.html",
	styleUrl: "./add-faq.component.css",
})
export class AddFaqComponent implements OnInit {
	question: any;
	description: any;
	isEdit = false;
	constructor(
		public dialogRef: MatDialogRef<AddFaqComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private toaster: ToasterService
	) {}

	ngOnInit(): void {
		console.log(this.data);
		this.isEdit = this.data.isEdit;
		this.question = this.data?.rowData?.question;
		this.description = this.data?.rowData?.description;
	}

	submit() {
		let payload;
		if (this.isEdit) {
			payload = {
				question: this.question,
				description: this.description,
				id: this.data?.rowData?.id,
			};
			if (!payload.question) {
				this.toaster.warning("Please enter question");
				return;
			}
			if (!payload.description) {
				this.toaster.warning("Please enter description");
				return;
			}
		} else if (!this.isEdit) {
			payload = {
				question: this.question,
				description: this.description,
			};
			if (!payload.question) {
				this.toaster.warning("Please enter question");
				return;
			}
			if (!payload.description) {
				this.toaster.warning("Please enter description");
				return;
			}
		}
		this.dialogRef.close(payload);
	}
	close() {
		this.dialogRef.close();
	}
}
